.myFooter {
    height: 106px;
    _background-color: rgb(204, 204, 255) !important;
}

.icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-indent: -10000px;
}

.facebook {
    background: url(../../assets/facebook.png) center/cover no-repeat;
}

.instagram {
    background: url(../../assets/instagram.png) center/cover no-repeat;
}

.viber {
    background: url(../../assets/viber.png) center/cover no-repeat;
}

.icon a {
    display: block;
    width: 100%;
    height: 100%;
}