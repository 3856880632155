@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
* {
  box-sizing: border-box;
}

html {
  font-family: 'PT Sans', sans-serif;
}

body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  background: url(./assets/academic.jpg) center/cover no-repeat;
}

body::after {
  content: '';
  display: block;
  height: 106px;
}

a {
  text-decoration: none;
  color: inherit;
}

.priceTd {
  min-width: 60px;
}

#changeLanguage {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  display: inline-block;
  border: 1px solid #999;
  cursor: pointer;
  background-color: transparent;
  font-size: 1rem;
  text-transform: uppercase;
}
#changeLanguageBtn {
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
}
#changeLanguageBtn:hover {
  background-color: rgb(232, 219, 219);
}